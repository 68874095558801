import { Controller } from "stimulus"
import Highcharts from "highcharts"
import exportConfig from '../export_config';
require('highcharts/modules/exporting')(Highcharts);

export default class extends Controller {
  connect() {
    let config = {
      colors: ['#f5dfdc', '#f9d643', '#009490', '#162359'],
      chart: {
        type: 'bar',
        style: {
          fontFamily: 'Montserrat',
          overflow: 'visible' // Set overflow to visible to prevent hiding the download button
        }
      },
      title: {
        text: 'Mean Scores',
        align: 'left',
        style: {
          color: '#159490',
          fontWeight: 'bold'
        } 
      },
      xAxis: {
        // dates instead of categor
        categories: JSON.parse(this.data.get("categories")),
        title: {
          text: null,
        },
        labels: {
          style: {
            fontSize: '12px'
          }
        }
      },
      yAxis: {
        min: 0,
        max: 100,
        title: {
          text: null
        },
        labels: {
          format: '{value}%',
          style: {
            fontSize: '12px'
          }
        }
      },
      tooltip: {
        valueSuffix: '%',
        style: {
          color: "#FF0000",
          fontSize: '12px'
        }
      },
      plotOptions: {
        series: {
          borderWidth: 0,
          dataLabels: {
            enabled: true,
            format: '{point.y:.1f}%',
            style: {
              fontSize: '12px'
            }
          },
          colorByPoint: true,
          states: {
            hover: {
              // by default; highcharts set our brightness to 0.1 which was washing out our #f5dfdc (peach)color
              // https://api.highcharts.com/highcharts/plotOptions.column.states.hover.brightness
              // by setting it to 0.05, the color does not wash out
              brightness: 0.05
            }
          }
        },
        bar: {
          dataLabels: {
            enabled: true
          }
        }
      },
      legend: {
        layout: 'vertical',
        align: 'left',
        verticalAlign: 'bottom',
        floating: false,
        borderWidth: 0,
        backgroundColor:
          Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
        symbolPadding: 0,
        symbolWidth: 0,
        symbolHeight: 0,
        squareSymbol: false,
        enabled: false
      },
      credits: {
        enabled: false
      },
      series: JSON.parse(this.data.get("series")),
      ...exportConfig
    }

    // We are delaying the loading of highcharts here because 
    // when the data is cached from a previous load highcharts 
    // loads too quickly causing the size of the chart to be incorrect.
    // Delaying the load fixes this. 
    setTimeout(() => {
      Highcharts.chart(this.element, config)
    }, 250)
  }
}
