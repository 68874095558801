import { Controller } from "stimulus";

export default class extends Controller {

  connect(){
    if (this.element.form){
      this.element.form.addEventListener("submit", (event) => {
        if (!this.element.value){
          this.element.disabled = true
        }
      });
    }
  }
}