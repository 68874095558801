import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "collapse", "checkbox", "conditionalInput"]

  connect() {
    this.toggleFields()
    this.updateCheckbox()
  }

  updateCheckbox() {
    // if any select fields have more than one option selected, disable the conditional checkbox, otherwise it is enabled
    // https://github.com/brandnewbox/swift/issues/775
    this.conditionalInputTargets.find(target => {
      let optionLength = []
      // if select2 has already been initialized on the element, we get the select options using select2
      if ($(target).data('select2')) {
        optionLength = $(target).select2('data').length
      } else {
        optionLength = target.selectedOptions.length
      }
      return optionLength > 1
    }) ? this.disableCheckbox() : this.enableCheckbox()
  }

  disableCheckbox() {
    this.checkboxTarget.checked = false
    this.checkboxTarget.disabled = true

    let event = new Event("change")
    this.checkboxTarget.dispatchEvent(event)
  }

  enableCheckbox() {
    this.checkboxTarget.disabled = false
  }

  toggleFields() {
    let isChecked = this.checkboxTarget.checked

    this.inputTargets.forEach(target => {
      target.disabled = isChecked
    })

    this.collapseTargets.forEach(target => {
      target.style.display = isChecked ? "none" : "block"
    })
  }
}
