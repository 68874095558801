import { Controller } from "stimulus"

import $ from 'jquery';

export default class extends Controller {
  connect() {
    JSON.parse(this.data.get("events")).forEach(event => {
      $(this.element).on(event, (e) => {
        // Detect if event is jQuery event.
        // Internet says `.isTrigger` is the best way to do so.
        if (e.isTrigger) {
          e.stopPropagation()
          let eventToTrigger = new CustomEvent(event, {
            detail: e
          })
          this.element.dispatchEvent(eventToTrigger)
        }
      })
    });
  }
}