import { Controller } from "stimulus"

export default class extends Controller {

  filterSelect(data, element_id) {
    let options = data.map(d => `<option value="${d.id}">${d.name}</option>`);
    let html = `<option value="">Select ${element_id.replace(/#user_(\w+)_id/, "$1").replace("_", " ")}</option>${options.join("")}`;
    $(element_id)
      .prop('disabled', false)
      .find('option')
      .remove()
      .end()
      .append(html);

    // Since we replace all the form options we need to trigger a change event here.
    let event = new Event("change")
    let element = document.querySelector(element_id)

    element.dispatchEvent(event)
  }

  filterSchool(event) {
    const elementId = `#${event.target.id == 'user_district_id' ? 'user_school_id' : 'user_assessment_school_ids'}`;
    let url = this.element.dataset.selectFilterIsUrlForZoneValue == "true" ? 'options_for_select.json' : 'assessments_options_for_school_select.json'
    $.ajax({
      url: `/select_filter/${url}`,
      data: "district_ids=" + Array.from(event.target.selectedOptions).map(option => option.value),
      element_id: elementId
    }).done((data) => this.filterSelect(data, elementId));
  }

  filterDistrict(event) {
    const elementId = `#${event.target.id == 'user_state_id' ? 'user_district_id' : 'user_assessment_district_ids'}`;
    let url = this.element.dataset.selectFilterIsUrlForZoneValue == "true" ? 'district_options_for_select.json' : 'assessments_options_for_district_select.json'
    $.ajax({
      url: `/select_filter/${url}`,
      data: { state_ids: Array.from(event.target.selectedOptions).map(option => option.value).join(",") },
      element_id: elementId
    }).done((data) => this.filterSelect(data, elementId));
  }
}
