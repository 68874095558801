import downloadSvg from '../assets/images/download.svg';

// This is a custom JS configuration to with all the exporting configuration for
// highcharts exporting/downloads. We import and use this in several stimulus
// controllers. -- TAT 3/27/24
const exportConfig = {
  navigation: {
    buttonOptions: {
      align: 'right', // Position the button to the right
      verticalAlign: 'bottom', // Position the button at the bottom
      y: 65, // Adjust the value to control the vertical position of the button
      x: 24
    }
  },
  exporting: {
    buttons: {
      contextButton: {
        text: 'Download this graph',
        menuItems: [
          'downloadPNG',  // Download PNG option
          'downloadJPEG', // Download JPEG option
          'downloadPDF',  // Download PDF option
          'downloadSVG',  // Download SVG option
        ],
        symbol: 'url(' + downloadSvg + ')',  // Custom symbol for the button
        symbolX: 20, // Adjust the x offset for the symbol position
        symbolY: 20,  // Adjust the y offset for the symbol position
        symbolStrokeWidth: 1, // Custom symbol stroke width
        theme: {
          style: {
            color: '#4462E9',  // Custom text color (blue)
          },
          fill: '#FFFFFF',     // Custom button background color (white)
          stroke: '#4462E9',   // Custom button outline color (blue)
          states: {
            hover: {
              fill: '#D3D3D3'  // Custom button background color on hover (light grey)
            },
            select: {
              fill: '#D3D3D3'  // Custom button background color when selected
            }
          }
        }
      }
    }
  }
};

export default exportConfig;