(function($) {
  $(document).on("turbolinks:load", function () {
    if($('body').hasClass('node-type-feature')) {
      $('.header-wrapper').next().removeClass('container page-body');
      $('.in-depth').wrap('<div class="in-depth-wrapper"><div class="container page-body"></div></div>');
      $('.resources').wrap('<div class="container page-body"></div>');
      $('.swift-in-60, .copy-body, .easy-breadcrumb').wrapAll('<div class="container page-body"></div>');
    }

    var pbVal = 0;

    $('#mail-flyout form .form-item input, #mail-flyout form .form-item textarea').on('change', function () {
      $(this).each(function () {
        if( $(this).val() !== '' ) {
          pbVal += 25;
        } else if( $(this).val() === '' ) {
          pbVal -= 25;
        }
        $('.webform-progressbar-inner').css('width',pbVal+'%');
      });
    });

    $('#accordion').accordion({
      collapsible: true,
      active: false,
      heightStyle: 'content',
      beforeActivate: function(){
        $('html, body').animate({scrollTop: $(this).offset().top - 100}, 'slow');
      }
    });

    $('.flyout-handler').click(function (e) {
      e.preventDefault();

      $('#mail-flyout').toggleClass('expanded');
    });

    var swift = window.swift = window.swift || (function () {
        var
            _initFlyouts = function () {
                var collapseFlyouts = function () {
                    $('.flyout.expanded').removeClass('expanded');
                };

                $('.flyout-handle').click(function () {
                    var $flyout = $(this).closest('.flyout'),
                        isExpanded = $flyout.hasClass('expanded')
                    ;
                    collapseFlyouts()
                    if (!isExpanded) {
                        $flyout.addClass('expanded');
                        $('input:first', $flyout).focus();
                    }
                });

                var $flyouts = $('.flyout').click(function (e) {
                    e.stopPropagation();
                });
                $(document).click(function (e) {
                    if (e.button === 0) {
                        collapseFlyouts();
                    }
                });

                var $firstFlyout = $flyouts.filter(location.hash);
                if ($firstFlyout && $firstFlyout.length === 1) {
                    $firstFlyout.addClass('expanded');
                }
            }
        ;

        return {
            initFlyouts: _initFlyouts
        };
    })();


    //webforms on page //
    $('.inline-form .webform-client-form').submit(function(e){
      e.preventDefault();
//      $('ajax-loader').show();
      var urlform = $(this).attr('action');
      var formId = $(this).attr('id');
      $('#' + formId).append('<div class="ajax-loader"></div>')
      $.ajax({
          type: "POST",
          url: urlform,
          data: $(this).serialize(),
          success: function (data) {
  //          console.log(scrollAnchor);
            $('#' + formId).hide();
            $('.inline-form .field-name-body').hide();
            $('#confirmation-' + formId).show();
  //          $('ajax-loader').hide();
          }
      });
      return false;
    });

    //replace Submit types with Go
    $('input[type="submit"]').prop('value', 'Go');


    //rating feedback
//    $('.fivestar-widget').submit(function(){
    $(document).on('click', '.fivestar-widget a', function(){
      console.log('submitted');
      if($('.star.on').length){
        console.log($('.star.on').length);
        $('.vote .rated').show();
      }else{
        $('.vote .rated').hide();
      }
    });

  //track downloads in GA
    $(document).on('click', '.track-download', function(){
      var resourceType = $(this).data('type');
      if($(this).data('url')){
        var resourceUrl = $(this).data('url');
      }else{
        var resourceUrl = $(this).attr('href');
      }
  //		alert(resourceType + ' ' + resourceUrl);
      ga('send', 'event', {
          eventCategory: 'Resource',
          eventAction: resourceType,
          eventLabel: resourceUrl,
          transport: 'beacon'
      });
    });

  }); //end Doc Ready

  /*$(function () {
      var swift = window.swift;
      swift.initFlyouts();
  });*/

})(jQuery); //end $(jQuery) support
;