import { Controller } from "stimulus"

export default class extends Controller {
  // filesTarget is going to contain the list of input elements we've added
  // files to - in other words, these are the input elements that we're going
  // to submit.
  static targets = ["files"]

  addFile(event) {
    // Grab some references for later
    const originalInput = event.target
    const originalParent = originalInput.parentNode
  
    // Create an element that contains our input element
    const selectedFile = document.createElement("li")
    selectedFile.className = "selected-file"
    selectedFile.append(originalInput)
  
    // Create label (the visible part of the new input element) with the name of
    // the selected file.
    var labelNode = document.createElement("label")
    var textElement = document.createTextNode(originalInput.files[0].name)
    labelNode.appendChild(textElement)
  
    // Create a button that will allow the removal of files
    var buttonNode = document.createElement("button")
    buttonNode.innerHTML = "X"
    buttonNode.classList.add(...["btn", "btn-link", "p-0", "mx-2", "text-danger"])
    buttonNode.type = "button"
    buttonNode.setAttribute("data-action", "multi-upload#removeFile")
    
    // Add the button to the label and add the label to the selected file
    // list item
    labelNode.appendChild(buttonNode)
    selectedFile.appendChild(labelNode)

    // Add the selected file to the list of selected files
    this.filesTarget.append(selectedFile)
  
    // Create a new input field to use going forward
    const newInput = originalInput.cloneNode()
  
    // Clear the filelist - some browsers maintain the filelist when cloning,
    // others don't
    newInput.value = ""
  
    // Add it to the DOM where the original input was
    originalParent.append(newInput)
  }

  removeFile(event) {
    event.target.parentNode.parentNode.remove();
  }
}
