import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["collapse"]

  connect() {
    this.collapseTargets.forEach((e) => {
      $(e).on("show.bs.collapse", this.toggle.bind(this));
      $(e).on("hide.bs.collapse", this.toggle.bind(this));
    })
  }

  toggle(event) {
    const icon = $(`#icon-${event.target.id}`)
    if (icon) {
      if (event.type === "show") {
        icon.removeClass("fa-caret-down");
        icon.addClass("fa-caret-up");
      } else if (event.type === "hide") {
        icon.removeClass("fa-caret-up");
        icon.addClass("fa-caret-down");
      }
    }
  }
}