import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "receiver" ]
  toggle(){
    let index = this.classList.findIndex( (classStr) => {
      return this.receiverTarget.classList.contains(classStr)
    })
    if (index != -1){
      this.receiverTarget.classList.remove(this.classList[index])
    }
    index +=1 
    if (index >= this.classList.length){
      index = 0 
    }
    this.receiverTarget.classList.add(this.classList[index])
  }
  get classList(){
    return JSON.parse(this.data.get("classes"))
  }
}