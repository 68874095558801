import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "fileTemplate", "linkTemplate", "form" ]

  activateFileForm() {
    this.addAssociation(this.fileTemplateTarget)
  }
  
  activateLinkForm() {
    this.addAssociation(this.linkTemplateTarget)
  }

  addAssociation(target) {
    let content = target.innerHTML.replace(/NEW_RECORD/g, new Date().valueOf())
    this.formTarget.insertAdjacentHTML("beforebegin", content)
  }

  removeAssociation(event) {
    let item = event.target.closest(".nested-fields")
    item.querySelector("input[name*='_destroy']").value = true
    item.style.display = 'none'
  }
}
