import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["checkboxToUncheck", "checkbox"]

  uncheck(event) {
    if (event.target == this.checkboxToUncheckTarget) {
      this.checkboxTargets.forEach((checkbox) => {
        checkbox.checked = false
      })
    } else {
      this.checkboxToUncheckTarget.checked = false
    }
  }
}