import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    let $this = $(this.element)
    $this.select2({
      placeholder: $this.data('placeholder'),
      dropdownAutoWidth : true,
      width: '100%'
    })
  }

  disconnect() {
    $(this.element).select2('destroy')
  }
}